.header-container {
  width: 95%;
}

@media only screen and (min-width: 768px) {
  .header-container {
    width: 60%;
  }
}

.header-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}